import axios from "axios";
import { PASSWORD_CHANGE } from "../../constant/API constant";

export const Password_Change = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${PASSWORD_CHANGE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
