import { DeleteForever } from "@material-ui/icons";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { Edit, Eye, Plus } from "react-feather";

import {
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { PRIVILLAGE } from "../../constant";
import PrivillageMasterContext from "../../context/PrivillageMasterContext/PrivillageMasterContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";

import Breadcrumbs from "../../layout/breadcrumb";

const OrganizationItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    DeleteHandle,
  } = props;

  const { formvalue, filter, setFilter } = useContext(PrivillageMasterContext);

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "id",
      headerName: "ID",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "method",
      headerName: "Method",
    },
    {
      flex: 0.1,
      minWidth: 280,
      field: "url",
      headerName: "URLs",
    },
    {
      flex: 0.04,
      minWidth: 180,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() => editHandle(params.row.id)}
          >
            <Edit style={{ width: "19px" }} />
          </button>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() => showHandle(params.row.id)}
          >
            <Eye style={{ width: "19px" }} />
          </button>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() => DeleteHandle(params.row.id)}
          >
            <DeleteForever style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={PRIVILLAGE.PERENT} />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{PRIVILLAGE.PERENT}</h3>
                  <button
                    type="button"
                    style={{ border: "none", background: "none" }}
                    onClick={AddModel}
                  >
                    <div
                      style={{
                        width: "50px",
                        background: "#7366ff",
                        borderRadius: "19rem",
                        color: "white",
                      }}
                    >
                      <h1>
                        <Plus />
                      </h1>
                    </div>
                  </button>
                </CardHeader>
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <Form className="search-file">
                          <Input
                            className="form-control-plaintext"
                            type="text"
                            placeholder="Privillage Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Box
                  sx={{
                    "& .even": {
                      backgroundColor: "#f1f1f1",
                    },
                    "& .odd": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  {formvalue.length && formvalue.length ? (
                    <DataGrid
                      autoHeight
                      pagination
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.id}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  ) : (
                    <DataNotFound />
                  )}
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default OrganizationItem;
