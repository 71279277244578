import axios from "axios";
import {
  EDIT_SHIPPING,
  SHIPPING_ADD,
  SHIPPING_DETAILS,
  SHIPPING_LIST,
} from "../../constant/API constant";

export const ShippingList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SHIPPING_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Shipping_Details = (shippingId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${SHIPPING_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { shippingId },
  });
};

export const Shiping_Edit = (formData, shippingId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_SHIPPING}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { shippingId },
    }
  );
};

export const Shipping_Add = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${SHIPPING_ADD}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
