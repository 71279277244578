import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container } from "reactstrap";

import { toast, Zoom } from "react-toastify";
import SupperAdminDeshboard from "./supperAdminDeshboard/supperAdminDeshboard";
import SellerDeshboaed from "./sellerDeshboard/sellerDeshboaed";
import LoadingBar from "react-top-loading-bar";
import { USER_ROLE } from "../../constant";
import { AdminDeshboard } from "../../api/Admin Deshboard/AdminDeshboardApi";
import { Sellerdeshboard } from "../../api/Seller Deshboard Api/SellerDeshboardApi";

const Ecommerce = () => {
  // const settings = {
  //   className: "center",
  //   centerMode: true,
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   speed: 500,
  //   centerPadding: "5px",
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const [progress, setProgress] = useState(0);

  const [deshboardData, setDeshboardData] = useState([]);
  const [deshboard, setDeshboard] = useState([]);

  // SELLER

  const [sellerDeshboaed, setSellerDeshboaed] = useState([]);
  const [orgDetails, setOrgDetails] = useState([]);
  const [genQr, setGenQr] = useState([]);
  const [genNfc, setGenNfc] = useState([]);
  const [order, setOrder] = useState([]);

  const getData = async () => {
    if (USER_ROLE.ADMIN === +localStorage.getItem("roleId")) {
      try {
        const { data } = await AdminDeshboard();
        if (data.status === 200) {
          setDeshboardData(data.payload.data);
          setDeshboard(data.payload.data.StatusWiseNfcOrder);
        } else {
          setDeshboardData([]);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const Seller = async () => {
    if (USER_ROLE.SELLER === +localStorage.getItem("roleId")) {
      try {
        const { data } = await Sellerdeshboard();

        if (data.status === 200) {
          setSellerDeshboaed(data.payload.data);
          setOrgDetails(data.payload.data.orgDetails);
          setGenQr(data.payload.data.genQr);
          setGenNfc(data.payload.data.genNfc);
          setOrder(data.payload.data.StatusWiseNfcOrder);
        } else {
          setSellerDeshboaed([]);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };
  useEffect(() => {
    Seller();
  }, []);

  useEffect(() => {
    setProgress(100);
  }, []);

  return (
    <Fragment>
      {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
        <Breadcrumb parent="Dashboard" title="Admin Dashboard" />
      ) : (
        <Breadcrumb parent="Dashboard" title="Dashboard" />
      )}
      <Container fluid={true}>
        <LoadingBar progress={progress} setProgress={setProgress} />
        {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
          <SupperAdminDeshboard
            deshboardData={deshboardData}
            deshboard={deshboard}
          />
        ) : (
          <SellerDeshboaed
            sellerDeshboaed={sellerDeshboaed}
            orgDetails={orgDetails}
            genQr={genQr}
            genNfc={genNfc}
            order={order}
          />
        )}
      </Container>
    </Fragment>
  );
};

export default Ecommerce;
