import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, SHIPPING_MASTER } from "../../constant";
import ShippingMasterContext from "../../context/ShippingMasterContext/ShippingMasterContext";
import LoaderResponse from "../../Reuseable/Loader";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const ShippingAddModel = (props) => {
  const { onSubmit, closeModel } = props;

  const {
    companyName,
    trackingLink,
    toggleSubmit,
    modal,
    setTrackingLink,
    setCompanyName,
  } = useContext(ShippingMasterContext);

  const { loaderShow } = useContext(LoaderContext);

  return (
    <Modal size="lg" isOpen={modal}>
      <ModalHeader toggle={() => closeModel()}>
        {toggleSubmit ? (
          <span>{SHIPPING_MASTER.ADD_SHIPPING_MASTER}</span>
        ) : (
          <span>
            {SHIPPING_MASTER.EDIT_SHIPPING_MASTER} <span> </span>{" "}
            <Edit style={{ width: "50px", height: "20px", color: "#7366ff" }} />
          </span>
        )}
      </ModalHeader>
      <ModalBody>
        <LoaderResponse />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <Row>
                    <Col md="12 mb-4">
                      <Label htmlFor="validationCustom1">
                        {SHIPPING_MASTER.COMPANY_NAME}
                      </Label>
                      <Input
                        className="form-control"
                        name="name"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4">
                      <Label htmlFor="validationCustom1">
                        {SHIPPING_MASTER.LINK}
                      </Label>
                      <Input
                        className="form-control"
                        name="link"
                        placeholder="Link"
                        value={trackingLink}
                        onChange={(e) => setTrackingLink(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Row>
                      <Col
                        md="12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          className="btn btn-primary"
                          type="submit"
                          value="Submit"
                          style={{ width: "15rem" }}
                          disabled={loaderShow === true}
                        >
                          {BUTTON.SAVE}
                        </button>
                      </Col>
                    </Row>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ShippingAddModel;
