import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CountUp from "react-countup";
const StatuswiseOrder = (props) => {
  const { deshboard } = props;
  return (
    <Container fluid={true}>
      <Row className="size-column">
        <Row>
          {deshboard.map((elem) => {
            return (
              <Col
                xl="4"
                lg="12"
                md="6"
                className="box-col-6"
                key={elem.orderStatusId}
              >
                <Card className="o-hidden">
                  <CardBody>
                    <div className="follow">
                      <Row>
                        <Col col="6" className="text-md-end border-end">
                          <div className="ttl-info text-start">
                            <p className="f-w-500 font-roboto">{"Count"}</p>
                            <h4>
                              <CountUp end={elem.count} />
                            </h4>
                          </div>
                        </Col>
                        <Col col="6" className="text-md-start">
                          <p className="f-w-500 font-roboto">{"Status"}</p>
                          <h6>{elem.statusName}</h6>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Row>
    </Container>
  );
};

export default StatuswiseOrder;
