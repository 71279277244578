import * as React from "react";
import { useState } from "react";
import ShippingMasterContext from "./ShippingMasterContext";

const ShippingMasterState = (props) => {
  const [shippValue, setShippValue] = useState([]);

  const [shippingId, setshippingId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [trackingLink, setTrackingLink] = useState("");
  const [editIndex, seteditIndex] = useState(-1);

  const [modal, setModal] = useState(false);

  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [totalRecord, setTotalRecord] = useState();
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->

  const [progress, setProgress] = useState(0);
  const [toggleSubmit, setToggleSubmit] = useState(false);

  const [isHovering1, setisHovering1] = useState(false);
  const [isHovering2, setisHovering2] = useState(false);
  const [isHovering3, setisHovering3] = useState(false);

  return (
    <>
      <ShippingMasterContext.Provider
        value={{
          shippValue,
          setShippValue,
          shippingId,
          setshippingId,
          companyName,
          setCompanyName,
          trackingLink,
          setTrackingLink,
          editIndex,
          seteditIndex,
          modal,
          setModal,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          totalRecord,
          setTotalRecord,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          isHovering,
          setIsHovering,
          progress,
          setProgress,
          toggleSubmit,
          setToggleSubmit,
          isHovering1,
          setisHovering1,
          isHovering2,
          setisHovering2,
          isHovering3,
          setisHovering3,
        }}
      >
        {props.children}
      </ShippingMasterContext.Provider>
    </>
  );
};

export default ShippingMasterState;
