import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";

const SellerDeshboaed = (props) => {
  const { sellerDeshboaed, orgDetails, genQr } = props;
  return (
    <Row className="size-column">
      <Row>
        <Col xl="4" lg="12" md="6" className="box-col-6">
          <Card className="o-hidden">
            <CardBody>
              <div className="follow">
                <Row>
                  <Col col="6" className="text-md-start">
                    <p className="f-w-500 font-roboto">{"Total Balance "}</p>
                    <h4>
                      <span>
                        <CountUp
                          end={Number(orgDetails.qrBal) + Number(genQr.count)}
                        />
                      </span>
                    </h4>
                  </Col>
                  <Col col="6" className="text-md-start">
                    <p className="f-w-500 font-roboto">{"Available Balance"}</p>
                    <h4>
                      <CountUp end={orgDetails.qrBal} />
                    </h4>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* <Col xl="4" lg="12" md="6" className="box-col-6">
          <Card className="o-hidden">
            <CardBody>
              <div className="follow">
                <Row>
                  <Col col="6" className="text-md-end border-end">
                    <p className="f-w-500 font-roboto">{"NFC Balance"}</p>
                    <h4>
                      <span>
                        <CountUp end={orgDetails.nfcBal} />
                      </span>
                    </h4>
                  </Col>
                  <Col col="6" className="text-md-start">
                    <p className="f-w-500 font-roboto">{"QR Balance"}</p>
                    <h4>
                      <CountUp end={orgDetails.qrBal} />
                    </h4>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col> */}
        <Col xl="4" lg="12" md="6" className="box-col-6">
          <Card className="o-hidden">
            <CardBody>
              <div className="follow">
                <Row>
                  {/* <Col col="6" className="text-md-end border-end"> */}
                  <Col col="6" className="text-md-start">
                    <p className="f-w-500 font-roboto">{"Total Order"}</p>
                    <h4>
                      <span>
                        <CountUp end={genQr.count} />
                      </span>
                    </h4>
                  </Col>
                  <Col col="6" className="text-md-start">
                    <p className="f-w-500 font-roboto">{"Today's Order"}</p>
                    <h4>
                      <CountUp end={sellerDeshboaed.todayQrOrder} />
                    </h4>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* {order.map((elem) => {
          return (
            <Col
              xl="4"
              lg="12"
              md="6"
              className="box-col-6"
              key={elem.orderStatusId}
            >
              <Card className="o-hidden">
                <CardBody>
                  <div className="follow">
                    <Row>
                      <Col col="6" className="text-md-end border-end">
                        <p className="f-w-500 font-roboto">{"Count"}</p>

                        <h4>
                          <CountUp end={elem.count} />
                        </h4>
                      </Col>
                      <Col col="6" className="text-md-start">
                        <p className="f-w-500 font-roboto">{"Status"}</p>
                        <h6>{elem.statusName}</h6>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })} */}
      </Row>
    </Row>
  );
};

export default SellerDeshboaed;
